import axios from 'axios'
import { Notify, Toast  } from 'vant';
import { getToken, removeToken } from './auth'
import { goPage  } from '@/utils/'
import { ApiUrl } from '@/config/sys-config'
//import router from '../router'

//const baseURL = process.env.NODE_ENV !== 'production' ? '/proxy' : ApiUrl
console.log(baseURL)
const baseURL = '/proxy'
// create an axios instance
const service = axios.create({
  baseURL: ApiUrl
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // 自定义加载图标
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
    const hasToken = getToken()
    console.log('token信息:',hasToken) 
    if (hasToken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = hasToken
    }
    // config.responseType = 'blob'
    return config
  },
  error => {
    Toast.clear()
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    Toast.clear()
    const res = response.data
    const code = res.code
    const message = res.msg
    if (code === 401) {
      removeToken()  // 清除TOKEN
      Notify({type:'danger',message});
      goPage('goToLogin',{})
      return false
    } else if (code === 500) {
      Notify({type:'danger',message});
      return false
    }
    return res
  },
  error => {
    Notify({type:'danger',message:'网络或接口异常：' + error.message,});
    return Promise.reject(error)
  }
)

export default service
