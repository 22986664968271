<template>
  <div class="slogon mt10">
     <div class="slogon-item" v-for="(item,index) in slogon" :key="index">
         <img :src="item" style="height:14px;" alt="">
     </div>
  </div>
</template>
<script>
 export default {
   data() {
    return {
       slogon:[
         require('@/assets/img/slogon/p1.png'),
         require('@/assets/img/slogon/p2.png'),
         require('@/assets/img/slogon/p3.png'),
         require('@/assets/img/slogon/p4.png'),
       ]
    }
   },
 }
</script>
<style lang="less" scoped>
.slogon {
   width: 351px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
</style>