<template>
  <div class="ad-box mt10">
      <div class="ad-small">
         <img :src="item.imgUrl" v-for="(item,index) in adSmalls" :key="index" @click="goHref(item)"/>
      </div>
      <img class="ad-big mt10"  :src="adBig.imgUrl" style="" @click="goHref(adBig)" alt="">
  </div>
</template>
<script>
 export default {
   data() {
    return {
       adBig: {
         href:'https://app.singlewindow.cn/cas/login?_loginCeb=1&service=https%3A%2F%2Fswapp.singlewindow.cn%2Fdeskserver%2Fsw%2FdeskIndex%3Fmenu_id%3Dswcebpub&noticket=true',
         imgUrl:require('@/assets/img/ad/gg1.png')
       },
       adSmalls:[
         {
           imgUrl: require('@/assets/img/ad/nav7.jpg'),
           path:'/biz1210'
         },
         {
           imgUrl: require('@/assets/img/ad/nav8.jpg'),
           path:'/storeHouse'
         }
       ]
    }
   },
   methods: {
      goHref(item){
         const path = item.path
         if(path){
            this.$router.push({
              path
            })
         }else{
           window.open(item.href)
         }
         
      }
   },
 }
</script>
<style lang="less" scoped>
.ad-box {
   width: 351px;
   .ad-small {
     display: flex;
     justify-content: space-between;
     align-items: center;
     img {
      width:170px;
      border-radius: 15px;
     }

   }
   .ad-big {
     width:100%;
     border-radius:15px;
   }
}
</style>