/**
 * 微信公众号 AppId 
 * 在自定义分享时起作用
 * 同时需要微信公众号后台，设置JS安全域名 test.h5.interescamp.com
 * */ 
//export const appId = 'wxcbf9cb3ffa50b196'   // 机构公众号，服务号
 export const appId = 'wxa6a3b354648afb1a' //跨境电商公众号， 服务号
/**
 * 跨境电商H5,域名前缀
 */
//export const h5Url = 'https://test.h5.interescamp.com'
export const h5Url = 'http://wechat.syftzid.com'
export const prefix_img =  process.env.NODE_ENV === 'production' ? h5Url :'/img'
export const ApiUrl = 'http://vxpublic.api.syftzid.com/web/api'