<template>
  <el-carousel height="171px">
    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
      <div class="banner">
        <el-image  :src="item" ></el-image>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      bannerList: [
        require("../../../assets/img/banner/banner1.png"),
        require("../../../assets/img/banner/banner2.jpg"),
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
.banner {
  width: 351px; 
  overflow: visible;
  overflow: hidden;
  img {
  border-radius: 10px;
  }
}

.el-carousel__button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
