import request from '@/utils/request'

// 获取签名接口
export function getSign (data) {
  return request({
  //   url: 'visit/getSign',
  url: 'auth/getJsApiTicket',
    method: 'post',
    data
  })
}

// 获取签名接口
export function getSiteInfo (data) {
  return request({
    url: 'visit/load',
    method: 'post',
    data
  })
}