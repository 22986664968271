<template>
  <div class="home">
     <!-- 轮播图 -->
     <BannerList></BannerList>
     <!--口号-->
     <slogon></slogon>
     <!--广告-->
     <advertisement></advertisement>
     <h3 class="title tal">跨境商城入口</h3>
      <!--商城入口-->
     <Malls></Malls>
     <div class="footer">
       <img :src="footerAD" alt="">
     </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import {  getSign } from "@/api/site";
// import { isMiniprogram } from "@/utils/index";
import { appId } from "@/config/sys-config";
import BannerList from './banner'
import slogon from './slogon'
import advertisement from './advertisement'
import Malls from './malls'
export default {
  components:{
   BannerList,
   slogon,
   advertisement,
   Malls
  },
  data() {
    return {
      footerAD:require('@/assets/img/ad/dt2.png')
    }
  },
  mounted() {
     this.setWxConfig();
  },
  methods: {
     // 获取签名信息
    setWxConfig() {
      const url = location.href.split("#")[0];
      getSign({ url }).then((res) => {
        if (res && res.code === 200) {
          const data = res.data;
          // 设置weixin-js-sdk接口配置，需要将域名在公众号中配置Js安全域名 
          wx.config({
            debug: false, //是否开启调试功能，这里关闭！
            appId, //appid
            timestamp: data.timestamp, //时间戳
            nonceStr: data.nonceStr, //生成签名的随机字符串
            signature: data.signature, //签名
            jsApiList: [
              "updateTimelineShareData",
              "updateAppMessageShareData"
            ],
            openTagList:['wx-open-launch-weapp']
          });
        }
      });
    },

  },
}
</script>
<style lang="less">
  .home {
    padding:12px;
  }
  .title {
     font-weight: 700;
     color:#222;
     margin-top:12px;
  }
  .footer {
     img {
       width:100%;
       border-radius: 10px
     }
  }
</style>