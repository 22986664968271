<template>
  <div class="mall-box">
    <ul class="mall-list">
      <!--正常链接跳转-->
      <li v-for="(mall, index) in mallList" :key="index" >
          <div v-if="!mall.isWxLabel" class="mall"  @click="handleHref(mall)">
            <img :src="mall.logoUrl" />
              <div class="mall-content tal">
                <h4>{{ mall.title }}</h4>
                <p class="follow">关注：{{ mall.follow }}</p>
                <p class="newAdd">较昨日新增：{{ mall.newAddCount }}</p>
              </div>
          </div>
         <div  class="mall" v-else>
             <wx-open-launch-weapp
                    id="launch-btn"
                   :username="mall.originalId"
                >
                <script type="text/wxtag-template">
                  <style> img {
                    width: 42px;
                    margin: 12px 10px;
                  }
                  .mall {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 169px;
                    height: 68px;
                    box-shadow: 0 0 15px #f0f0f0;
                    border-radius: 8px;
                    margin-bottom: 12px;
                     padding-top:10px;
                  }
                  h4,p {
                      padding: 0;
                      margin: 0;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 100px;
                    }
                    h4 {
                      color: #222;
                    }
                    p {
                      font-size: 12px;
                      color: #b2b2b2;
                    }</style>
                  <div class="mall">
                    <img src="{{mall.logoUrl}}" />
                    <div class="mall-content tal">
                      <h4>{{ mall.title }}</h4>
                      <p class="follow">关注：{{ mall.follow }}</p>
                      <p class="newAdd">较昨日新增：{{ mall.newAddCount }}</p>
                    </div>
                  </div>
                </script>     
                </wx-open-launch-weapp>
          </div>
      </li>
    </ul>
    <!-- <div style="width: 100%; height: 100%;">
        <wx-open-launch-weapp          
            id="launch-btn"
            username="gh_74a6833da088"
        >       
        <script type="text/wxtag-template">
            <style>.btn { padding: 12px}</style>
            <button class="btn">跳转小程序</button>
            </script>     
        </wx-open-launch-weapp> 
    </div> -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      top="5vh"
      :width="dialogWidth"
      :before-close="closeDialog">
      <img style="width:100%" :src="qrcodeImgUrl">
    </el-dialog>
  </div>
</template>
<script>
import { isWeiXin,getOSType } from '@/utils'
import { h5Url  } from '@/config/sys-config';
export default {
  
  data() {
    const isWeiXinLabel = isWeiXin()
    // 兼容处理修改手机端与Pc端，弹窗展示处理
    const dialogWidth = getOSType() ==='H5' ? '30%' :'100%'
    return {
      
      isWeixinEnd: false,
      qrcodeImgUrl:'',
      dialogVisible: false,
      title: "扫码提示",
      adBig: require("@/assets/img/ad/gg1.png"),
      dialogWidth,
      mallList: [
        {
          logoUrl: require("@/assets/img/company-logo/lb2.png"),
          title: "沈阳自贸跨境电商平台",
          follow: 552,
          newAddCount: 10,
          href: "https://m.wmshop.lnfta.com",
          isWxLabel:false,
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: `${h5Url}/pic/shengdamen.png`,
          title: "沈阳自贸区盛大门全球精品直达店",
          follow: 6753,
          newAddCount: 11,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_95ad45315e9a',// 使用小程序_原始ID 进行页面跳转
          href: require("@/assets/img/qrcode/qrcode-shengdamen.jpg"),   // 没有跳转链接放置二维码图片地址
          isHref: false, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: require("@/assets/img/company-logo/lb3.png"),
          title: "云海淘",
          follow: 5982,
          newAddCount: 10,
          isWxLabel:false,
          href: "http://mall.yunhaitao.com/index.php/wap/shop/index?shop_id=1",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: require("@/assets/img/company-logo/lb9.png"),
          title: "跨境海淘云仓",
          follow: 7661,
          newAddCount: 1,
          isWxLabel:false,
          href: "https://shop43903773.m.youzan.com/v2/showcase/homepage?kdt_id=43711605",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        // {
        //   logoUrl: require("@/assets/img/company-logo/lb7.png"),
        //   title: "百宝云仓",
        //   follow: 3251,
        //   newAddCount: 21,
        //   isWxLabel:false,
        //   href: "https://shop90398389.youzan.com/v2/feature/uli5lauyTz",
        //   isHref: true, // 链接直接跳转， 否则使用小程序标签
        // },
        {
          logoUrl: require("@/assets/img/company-logo/lb4.png"),
          title: "沈阳自贸海外购",
          follow: 123,
          newAddCount: 1,
          isWxLabel:false,
          href: "https://shop.ln-sx.cn/#/pages/home/index?shop=sxkj",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: require("@/assets/img/company-logo/lb12.png"),
          title: "赫睿翔旗舰店",
          follow: 451,
          newAddCount: 0,
          isWxLabel:false,
          href: "https://shop.ln-sx.cn/#/pages/home/index?shop=hrx",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        // {
        //   logoUrl: require("@/assets/img/company-logo/lb10.png"),
        //   title: "海外购一号店",
        //   follow: 8478,
        //   newAddCount: 10,
        //   isWxLabel:false,
        //   href: "https://shop44251294.youzan.com/v2/showcase/homepage?alias=6XeTMzfXJr",
        //   isHref: true, // 链接直接跳转， 否则使用小程序标签
        // },
        {
          logoUrl: require("@/assets/img/company-logo/lb11.png"),
          title: "华岳跨境",
          follow: 9028,
          newAddCount: 10,
          isWxLabel:false,
          href: "https://shop.ln-sx.cn/#/pages/home/index?shop=huayue",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: `${h5Url}/pic/yuntong.png`,
          title: "云通全球购",
          follow: 4013,
          newAddCount: 10,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_5324be9d528a',// 使用小程序_原始ID 进行页面跳转
          href: require("@/assets/img/qrcode/erweima-yunei.png"),   // 没有跳转链接放置二维码图片地址
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: require("@/assets/img/company-logo/lb15.png"),
          title: "安卡365商城",
          follow: 445,
          newAddCount: 10,
          isWxLabel:false,
          href: "https://anka365.com/app/index.php?i=5&c=entry&m=ewei_shopv2&do=mobile&mid=2721",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: `${h5Url}/pic/shilu.png`,
          title: "丝路全球购",
          follow: 0,
          newAddCount: 0,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_bce052f45d90',// 使用小程序_原始ID 进行页面跳转
          href: require("@/assets/img/qrcode/erweima-silu.png"),   // 没有跳转链接放置二维码图片地址
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: require("@/assets/img/company-logo/lb17.png"),
          title: "iEquine爱以宽国际马术商城",
          follow: 198,
          newAddCount: 23,
          isWxLabel:false,
          href: "https://m.iequ.net/home/index",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: `${h5Url}/pic/wanjiang.png`,
          title: "万疆优选跨境商城",
          follow: 16,
          newAddCount: 4,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_ae4153e0207e',// 使用小程序_原始ID 进行页面跳转
          href: require("@/assets/img/qrcode/wanjiang-code.jpg"),   // 没有跳转链接放置二维码图片地址
          isHref: false, // 链接直接跳转， 否则使用小程序标签
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    handleHref(item){
      const malls = ["沈阳自贸区盛大门全球精品直达店","云通全球购","丝路全球购",'万疆优选跨境商城']
      console.log(malls.includes(item.title))
      if(malls.includes(item.title)){
        // 用弹窗显示二维码扫码
        this.dialogVisible = true;
        this.qrcodeImgUrl = item.href;
        this.title = '扫码进入小程序'
      }else if(item.href){
         window.open(item.href)
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.mall-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .mall {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 169px;
    height: 68px;
    box-shadow: 0 0 15px #f0f0f0;
    border-radius: 8px;
    margin-bottom: 12px;
    img {
      width: 42px;
      margin: 12px 10px;
    }
    .mall-content {
      h4,
      p {
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
      h4 {
        color: #222;
      }
      p {
        font-size: 12px;
        color: #b2b2b2;
      }
    }
  }
}
</style>
